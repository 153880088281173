import Jasmia1 from '../Jasmia 12 Attic/Jasmia 12.webp'
import Jasmia2 from '../Jasmia 12 Attic/Jasmia 12 Attic L.webp'




const JasmiaAtticimages =[
Jasmia1,Jasmia2
]

export default JasmiaAtticimages;