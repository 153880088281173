import React from "react";
import "./produk.scss";
import Viola from "../../Media/Foto-About/Viola P.webp";
import Regia from "../../Media/Foto-About/Regia P.webp";
import Vanica from "../../Media/Foto-About/Vanica P.webp";
import Jasmia from "../../Media/Foto-About/Jasmia P.webp";

const produk = () => {
  const Violawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Viola)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Regiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Regia)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Vanicawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Vanica)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Jasmiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Jasmia)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div id="produk" className="produk-kensington">
      <div className="container">
        <div className="Judul-Produk">
          Pilihan Cluster Summarecon Crown Gading
        </div>
        <div className="pilihan-produk">Cicilan Start 5 Juta/Bulan</div>

        <div className="container-produk">
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Viola} alt={Viola} />
            </div>
            <div className="judulproduk">Viola</div>
            <div className="judulproduk2">Cicilan Start 5 Juta/Bulan</div>

            <a href="#Viola">Learn More</a>
            <button className="buttonpenawaran" onClick={Violawa}>
              Whatsapp
            </button>
          </div>
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Regia} alt={Regia} />
            </div>
            <div className="judulproduk">Regia</div>
            <div className="judulproduk2">Cicilan Start 5 Juta/Bulan</div>

            <a href="#Regia">Learn More</a>
            <button className="buttonpenawaran" onClick={Regiawa}>
              Whatsapp
            </button>
          </div>
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Vanica} alt={Vanica} />
            </div>
            <div className="judulproduk">Vanica</div>
            <div className="judulproduk2">Cicilan Start 9 Juta/Bulan</div>

            <a href="#Vanica">Learn More</a>
            <button className="buttonpenawaran" onClick={Vanicawa}>
              Whatsapp
            </button>
          </div>
          <div className="produk">
            <div className="containerprodukimg">
              <img src={Jasmia} alt={Jasmia} />
            </div>
            <div className="judulproduk">Jasmia</div>
            <div className="judulproduk2">Cicilan Start 14 Juta/Bulan</div>

            <a href="#Jasmia">Learn More</a>
            <button className="buttonpenawaran" onClick={Jasmiawa}>
              Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default produk;
