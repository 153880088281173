import Vanica1 from '../Vanica 7/Vanica 7.webp'
import Vanica2 from '../Vanica 7/Vanica 7 Luxury.webp'
import Vanica3 from '../Vanica 7/7 Premium.webp'



const Vanica7images =[
Vanica1,Vanica2,Vanica3
]

export default Vanica7images;