import Jasmia1 from '../Jasmia 10/Jasmia 10.webp'
import Jasmia2 from '../Jasmia 10/Jasmia 10 P.webp'
import Jasmia3 from '../Jasmia 10/Jasmia 10 D.webp'
import Jasmia4 from '../Jasmia 10/Jasmia 10 Attic.png'



const Jasmia10images =[
Jasmia1,Jasmia2,Jasmia3,Jasmia4
]

export default Jasmia10images;