import Vanica1 from '../Vanica 9/Vanica 9.webp'
import Vanica2 from '../Vanica 9/Luxury 9.webp'
import Vanica3 from '../Vanica 9/9 Premium.webp'



const Vanica9images =[
Vanica1,Vanica2,Vanica3
]

export default Vanica9images;