import React from "react";
import "./Jasmia.scss";
import data from "../Jasmia/data.json";
import Card from "../../Component/Card Jasmia/cardjasmia";
import Jasmia9images from "../../Media/SCG/Jasmia/Jasmia 9/Jasmia9";
import Jasmia10images from "../../Media/SCG/Jasmia/Jasmia 10/Jasmia10";
import Jasmia12images from "../../Media/SCG/Jasmia/Jasmia 12/Jasmia12";
import JasmiaAtticimages from "../../Media/SCG/Jasmia/Jasmia 12 Attic/Jasmia12Attic";
import logojasmia from "../../Media/Jasmia.webp";

const imageMap = {
  Jasmia9images,
  Jasmia10images,
  Jasmia12images,
  JasmiaAtticimages,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Jasmia" className="produk">
      <div className="logojasmia">
        <img src={logojasmia} alt={logojasmia} />
      </div>
      <h2>Cicilan Start 14 Juta/Bulan</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
