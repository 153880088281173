import Regia1 from '../Regia 7/Regia 7.webp'
import Regia2 from '../Regia 7/Regia 7A P.webp'
import Regia3 from '../Regia 7/Regia 7A D.webp'
import Regia4 from '../Regia 7/Regia 7B P.webp'
import Regia5 from '../Regia 7/Regia 7B D.webp'


const Regia7images =[
Regia1,Regia2,Regia3,Regia4,Regia5
]

export default Regia7images;