import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import gadingimages from "../../../Media/Gading/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBuilding,
  faLayerGroup,
  faMedal,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";

import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const CrystalWa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Gading%20Boulevard)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="ruko" className="container">
      <div className="containercard-ruko">
        <div className="card">
          <Slider {...settings}>
            {gadingimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Gading Boulevard</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#C88C39"
                icon={faMedal}
              />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#334442" icon={faArrowsUpDownLeftRight} />
            <span> 75m²</span>
            <FontAwesomeIcon color="#334442" icon={faBuilding} />
            <span> 194m²</span>
            <FontAwesomeIcon color="#334442" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="#334442" icon={faToilet} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">17 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="cluster-button">
            <button onClick={CrystalWa} className="cluster-whatsapp">
              <FontAwesomeIcon size="lg" icon={faWhatsapp} />
              &nbsp;Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
