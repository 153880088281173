import Jasmia1 from '../Jasmia 12/Jasmia 12 B.webp'
import Jasmia2 from '../Jasmia 12/Jasmia 12 P.webp'
import Jasmia3 from '../Jasmia 12/Jasmia 12 D.webp'
import Jasmia4 from '../Jasmia 12/12b Jasmia (1).png'



const Jasmia12images =[
Jasmia1,Jasmia2,Jasmia3,Jasmia4
]

export default Jasmia12images;