import React from "react";
import "./Viola.scss";
import data from "../Viola/data.json";
import Card from "../../Component/Card Viola/cardviola";
import Viola5images from "../../Media/SCG/Viola/Viola 5/Viola5";
import Viola6images from "../../Media/SCG/Viola/Viola 6/Viola6";
import Viola7images from "../../Media/SCG/Viola/Viola 7/Viola7";
import Viola8images from "../../Media/SCG/Viola/Viola 8/Viola8";
import logoviola from "../../Media/Viola.webp";

const imageMap = {
  Viola5images,
  Viola6images,
  Viola7images,
  Viola8images,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Viola" className="produk">
      <div className="logoviola">
        <img src={logoviola} alt={logoviola} />
      </div>
      <h2>Cicilan Start From 5 Juta/Bulan</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
