import Regia1 from '../Regia 8/Regia 8.webp'
import Regia2 from '../Regia 8/Regia 8 P.webp'
import Regia3 from '../Regia 8/Regia 8 D.webp'



const Regia8images =[
Regia1,Regia2,Regia3
]

export default Regia8images;