import React from "react";
import "./Vanika.scss";
import Myztro1 from "./Vanika/Vanika1.jsx";
import Soon from "./comingsoon/soon.jsx";

const apartemen = () => {
  return (
    <div id="Newlaunch" className="apartment">
      <div className="container-header1"> New Launching </div>
      <div className="container-header2"> Vanica</div>
      <div className="container-header3"> Summarecon Crown Gading</div>
      <div>
        <div className="Judul"></div>
        <Soon />
        <Myztro1 />
      </div>
    </div>
  );
};

export default apartemen;
