import React from "react";
import "./Secondhero.scss";
import contentlogo from "../../Media/logo_scg 2.png";
import kiriatas from "../../Media/Foto-About/Viola P.webp";
import kiribawah from "../../Media/Foto-About/Regia L.webp";
import kananatas from "../../Media/Foto-About/Jasmia L.webp";
import kananbawah from "../../Media/Foto-About/Vanica P.webp";

const SecondHero = () => {
  return (
    <div className="secondherocontainer" id="about">
      <div className="secondmiddle">
        <div className="leftcontent">
          <img src={contentlogo} className="leftlogo" alt="Nava" />
          <div className="content-judul">
            Balanced Living for Harmonious Life
          </div>
          <div className="contentdescription">
            Summarecon Crown Gading adalah proyek pengembangan kawasan berskala
            kota dengan luas mencapai 437 hektar. Proyek Summarecon Crown Gading
            dikembangkan oleh Developer ternama Summarecon Tbk. Summarecon Crown
            Gading untuk meingkatkan kenyamanan penghuni memberikan berbagai
            fasilitas seperti Clubhouse, One Gate System serta didukung dengan
            lingkungan sekitar yang dapat memenuhi kebutuhan penghuninya baik
            dari segi Pendidikan, Hiburan, Area Komersil, Serta Akses
            Transportasi. Summarecon Crown Gading juga memiliki Akses Tol
            Langsung di dalam kawasan.
          </div>
        </div>
        <div className="rightcontent">
          <div className="leftpicture">
            <div className="kiriatas">
              <img src={kiriatas} className="satu" alt="Nava" />
            </div>
            <div className="kiribawah">
              <img src={kiribawah} className="dua" alt="Nava" />
            </div>
          </div>
          <div className="rightpicture">
            <div className="kananatas">
              <img src={kananatas} className="tiga" alt="Nava" />
            </div>
            <div className="kananbawah">
              <img src={kananbawah} className="empat" alt="Nava" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondHero;
