import React from "react";
import "./soon.scss";
import Comingsoon from "../../../Media/Soon/Desktop.webp";
import Soon from "../../../Media/Soon/Mobile.webp";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6285215127964&text=Halo%20Marketing%20Summarecon,%20Saya%C2%A0bisa%20minta%20detail%20Promo,%20Brosur,%20dan Katalog%20produk%20terbaru%20Summarecon%20Serpong%20(Louise)%20https://marketinggadingserpong.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const soon = () => {
  return (
    <div id="louise" className="container-soon">
      <div className="maps-lokasi">
        <img className="img-lokasi-dekstop" src={Comingsoon} alt="maps" />
        <img id="lokasi" className="img-lokasi-mobile" src={Soon} alt="maps" />
      </div>
    </div>
  );
};

export default soon;
