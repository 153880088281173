import React from "react";
import "./Regia.scss";
import data from "../Regia/data.json";
import Card from "../../Component/Card Regia/card";
import Regia5images from "../../Media/SCG/Regia/Regia 5/Regia5";
import Regia6images from "../../Media/SCG/Regia/Regia 6/Regia6";
import Regia7images from "../../Media/SCG/Regia/Regia 7/Regia7";
import Regia8images from "../../Media/SCG/Regia/Regia 8/Regia8";
import logoregia from "../../Media/Regia.webp";

const imageMap = {
  Regia5images,
  Regia6images,
  Regia7images,
  Regia8images,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Regia" className="produk">
      <div className="logoregia">
        <img src={logoregia} alt={logoregia} />
      </div>
      <h2>Cicilan Start 5 Juta/Bulan</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
