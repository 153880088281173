import React from "react";
import "./lokasi.scss";
import lokasiimages from "../../Media/Maps.png";

const lokasi = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20(Lokasi)%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="Lokasi" className="container-lokasi">
      <div className="lokasi">
        <img
          className="lokasi-tallasa"
          src={lokasiimages}
          alt="hardsell-summarecon-serpong"
        />
      </div>
      <div className="container-judul">
        <div className="judul-lokasi">
          <h1>Lokasi </h1>
          <h2>Summarecon</h2>
          <h3>Crown Gading</h3>
          <div className="deskripsi">
            <h4>Lokasi yang ideal</h4>
            <h5>untuk tinggal dan berbisnis</h5>
            <h6>dengan banyak infrastruktur</h6>
          </div>
        </div>
        <div className="button-penawaran">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default lokasi;
