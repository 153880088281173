import React from "react";
import "./Vanica.scss";
import data from "../Vanika/data.json";
import Card from "../../Component/Card Vanica/card";
import Vanica7images from "../../Media/SCG/Vanica/Vanica 7/Vanica7";
import Vanica9images from "../../Media/SCG/Vanica/Vanica 9/Vanica9";
import logovanica from "../../Media/logovanica.png";

const imageMap = {
  Vanica7images,
  Vanica9images,

  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="Vanica" className="produk">
      <div className="logovanica">
        <img src={logovanica} alt={logovanica} />
      </div>
      <h2>Cicilan Start 9 Juta/Bulan</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
