import React from "react";
import "./Surrounding.scss";
import aeon from "../../Media/Surrounding/Al-Azhar School.webp";
import prasmul from "../../Media/Surrounding/BPK Penabur School.webp";
import binus from "../../Media/Surrounding/Living Plaza.webp";
import breeze from "../../Media/Surrounding/Hotel Santika.webp";
import qbig from "../../Media/Surrounding/Courts.webp";
import eka from "../../Media/Surrounding/BCA.webp";
import tol from "../../Media/Surrounding/Esa Unggul University.webp";
import sekolah from "../../Media/Surrounding/Eka Hospital.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Surrounding = () => {
  const slides = [
    {src: aeon, des: "Al-Azhar University"},
    {src: prasmul, des: "BPK Penabur School"},
    {src: binus, des: "Living Plaza"},
    {src: breeze, des: "Hotel Santika"},
    {src: qbig, des: "Courts"},
    {src: eka, des: "BCA"},
    {src: tol, des: "Esa Unggul University"},
    {src: sekolah, des: "Eka Hospital"},
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="surrounding" className="surroundingcontainer">
      <div className="title">Surrounding Area</div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Surrounding;
