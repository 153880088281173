import Jasmia1 from '../Jasmia 9/Jasmia 9.webp'
import Jasmia2 from '../Jasmia 9/Jasmia 9 P.webp'
import Jasmia3 from '../Jasmia 9/Jasmia 9 D.webp'
import Jasmia4 from '../Jasmia 9/Jasmia 9 Attic.png'



const Jasmia9images =[
Jasmia1,Jasmia2,Jasmia3,Jasmia4
]

export default Jasmia9images;