import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import Banner from "../Section/Banner/banner";
import Second from "../Section/secondhero/SecondHero.jsx";
import Vanica from "../Section/Vanica/Vanika.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Produk from "../Section/Produk/produk.jsx";
import Viola from "../Section/Viola/Viola.jsx";
import Regia from "../Section/Regia/Regia.jsx";
import Vanika from "../Section/Vanika/Vanica.jsx";
import Jasmia from "../Section/Jasmia/Jasmia.jsx";
import Ruko from "../Section/Ruko/ruko.jsx";
import Surrounding from "../Section/surrounding/Surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import Scrolltohashelement from "../Pages/ScrollToHashElement";
import tombol from "../Media/tombol.webp";
const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285158337358&text=Halo%20Nadya,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Summarecon%20Crown%20Gading%20https://crowngadingmarketing.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <Scrolltohashelement />
      <Navbar />
      <Banner />
      <Second />
      <Vanica />
      <Promo />
      <Produk />
      <Viola />
      <Regia />
      <Vanika />
      <Jasmia />
      <Ruko />
      <Surrounding />
      <Lokasi />
      <Footer />

      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
