import React, {useState} from "react";
import "./footer.scss";
import logo from "../../Media/logo_scg 2.png";
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const [isOpen] = useState(false);
  return (
    <div className="container-footer">
      <div className="footer">
        <div className="container-atas">
          <div className="container-logo">
            <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
          </div>
          <div className={isOpen ? "menus active" : "menus"}>
            {" "}
            Website Map
            <ul>
              <li>
                <HashLink className="link" smooth to="#about">
                  About
                </HashLink>
              </li>
              <li>
                <HashLink className="link" smooth to="#promo">
                  Promo
                </HashLink>
              </li>
              <li>
                <HashLink className="link" smooth to="#produk">
                  Residentials
                </HashLink>
              </li>
              <li>
                <HashLink className="link" smooth to="#ruko">
                  Ruko Commercials
                </HashLink>
              </li>

              <li>
                <HashLink className="link" smooth to="#Lokasi">
                  Location
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="container-deskripsi">
            <div className="alamat">
              <h1>Marketing Gallery</h1>
              <h2>Summarecon Crown Gading</h2>
              <h3>Dapatkan Informasi Promo, Brosur dan Pricelist Terbaru</h3>
              <h4>Contact us : +6285158337358 (Nadya)</h4>
              <Link to="/privacy-policy" color="white">
                <div className="footer-contact">Privacy Policy</div>
              </Link>
            </div>
            <div className="privasi"></div>
          </div>
        </div>
        <hr color="white" />
        <div className="container-bawah">
          <div className="rights">All rights reserved</div>
          <div className="judulcontainerbawah">
            <div className="copyright">© Summarecon Crown Gading</div>
            <div className="powered">Powered by Linktown</div>
          </div>
          <div className="backtotop">
            <ul>
              <li>
                <HashLink className="link" smooth to="#navbar">
                  Back to Top
                </HashLink>
              </li>
            </ul>
            <FontAwesomeIcon icon={faArrowCircleUp} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
